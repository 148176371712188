<template>
  <div v-if="user && traineeRoles && regions && phases" class="text-left">
    <form v-on:submit.prevent="updateUser">

      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top" v-if="isAdmin">Editing User: <small>{{ user.first_name }} {{ user.last_name }}</small></h4>
          <h4 id="top" v-else>Change Password</h4>

        </div>
      </div>

      <danger-alert :errors="errors"></danger-alert>

      <div class="form-group" v-if="isAdmin">
        <label for="email">E-mail</label>
        <input type="text" id="email" class="form-control" v-model="user.email" />
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="user_first_name">First Name</label>
        <input type="text" class="form-control" id="user_first_name" v-model="user.first_name">
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="user_last_name">Last Name</label>
        <input type="text" class="form-control" id="user_last_name" v-model="user.last_name">
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="role">Role</label>
        <select class="form-control" id="role" v-model="user.role">
          <option value="Admin">Admin</option>
          <option value="Sales Coach">Sales Coach</option>
          <option value="Service Coach">Service Coach</option>
          <option value="Trainee">Trainee</option>
        </select>
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="disabled">Disabled</label>
        <select class="form-control" id="disabled" v-model="user.disabled">
          <option v-bind:value="true">true</option>
          <option v-bind:value="false">false</option>
        </select>
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="enable-email-notifications">Enable Email Notifications</label>
        <select class="form-control" id="enable-email-notifications" v-model="user.enable_email">
          <option v-bind:value="true">true</option>
          <option v-bind:value="false">false</option>
        </select>
      </div>

      <div class="form-group" v-if="isAdmin">
      <label for="trainee_role">Trainee Role</label>
      <select class="form-control" id="trainee_role" v-model="user.trainee_role_id">
        <option v-for="(traineeRole, index) in traineeRoles" v-bind:key="index" :value="traineeRole.id">{{ traineeRole.title }}</option>
      </select>
    </div>

      <div class="form-group" v-if="isAdmin">
        <label for="phase">Phase</label>
        <select class="form-control" id="phase" v-model="user.phase_id">
          <option v-for="(phase, index) in phases" v-bind:key="index" :value="phase.id">{{ phase.title }}</option>
        </select>
      </div>

      <div class="form-group" v-if="isAdmin || user.uuid === token.uuid">
        <label for="user_password">Enter New Password</label>
        <input id="user_password" class="form-control" type="password" v-model="user.password" />
      </div>

      <div class="form-group" v-if="isAdmin || user.uuid === token.uuid">
        <label for="new_user_password">Confirm New Password</label>
        <input type="password" id="new_user_password" class="form-control" v-model="user.password_confirmation" />
      </div>

      <div class="form-group" v-if="isAdmin">
        <label for="user-archived">Is Archived?</label>
        <b-checkbox id="user-archived" v-model="user.is_archived"/>
      </div>

      <div class="form-group float-right">
        <div class="btn-group">
          <router-link :to="{ name: 'UserShow', params: { id: this.$route.params.id } }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
          <input v-if="isAdmin" type="submit" class="btn btn-success" value="Update User">
          <input v-else type="submit" class="btn btn-success" value="Change Password">

        </div>
      </div>
    </form>
  </div>
  <loading-overlay v-else />
</template>

<script>
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import DangerAlert from '@/common/components/danger-alert.vue';
import JwtService from '@/common/services/jwt.service';
import { UserService } from '@/common/services/user.service';
import PermissionService from '@/common/services/user_permissions.service';
import { FilterService } from '@/common/services/filter.service';
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import { PhaseService } from '@/common/services/phase.service';
import { RegionService } from '@/common/services/region.service';

export default {
  name: 'UserEdit',
  components: {
    LoadingOverlay,
    DangerAlert,
  },
  data() {
    return {
      user: null,
      traineeRoles: null,
      regions: null,
      phases: null,
      errors: [],
      isAdmin: false,
      token: JwtService.parseJwt(),
    };
  },
  created() {
    this.getUser();
    this.isAdmin = PermissionService.isAdmin();
  },
  methods: {
    getUser() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 500 }, this.filters);
      const token = JwtService.parseJwt();
      UserService.get(this.$route.params.id)
        .then((response) => {
          this.user = response.data;

          this.setUserProps();

          if (this.user.id !== token.id) {
            if (token.role !== 'Admin') {
              this.$router.go(-1);
            }
          }
          return this.getTraineeRoles(params);
        })
        .then((response) => {
          this.traineeRoles = response.data;
          return this.getPhases(params);
        })
        .then((response) => {
          this.phases = response.data;
          return this.getRegions(params);
        })
        .then((response) => {
          this.regions = response.data;
        });
    },
    updateUser() {
      UserService.update(this.user.id, this.user)
        .then(() => {
          this.$router.push({ name: 'UserShow', id: this.user.id }, () => {
            this.$notify({
              title: `Updated ${this.user.email}!`,
              text: 'Successfully updated user.',
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
    getTraineeRoles(params) {
      return TraineeRoleService.query(params);
    },
    getPhases(params) {
      return PhaseService.query(params);
    },
    getRegions(params) {
      return RegionService.query(params);
    },
    setUserProps() {
      if (this.user.phase) {
        this.user.phase_id = this.user.phase.id;
      } else {
        this.user.phase_id = null;
      }

      if (this.user.region) {
        this.user.region_id = this.user.region.id;
      } else {
        this.user.region_id = null;
      }

      if (this.user.trainee_role) {
        this.user.trainee_role_id = this.user.trainee_role.id;
      } else {
        this.user.trainee_role_id = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/users";
</style>
